<template>
  <div>
    <section class="bg-white w-screen" v-if="mostrarDireccion">
      <div class="container mx-auto">
        <!--div
          class="
            h-64
            md:h-96 md:col-span-3
            bg-[url('1x/barra-80.jpg')] bg-center bg-cover
          "
        ></div-->
        <div class="grid grid-cols-1 place-items-center">
          <div class="p-16 text-left">
            <img
              src="elite/512w/icono.png"
              class="w-16 opacity-50 inline-block pb-4"
            />
            <p>Avenida Chapultepec 1922, nivel 8</p>
            <p>Oficina B8, Desarrollos del Pedregal</p>
            <p>78295 San Luis Potosí, S.L.P.</p>
            <p>444.180.8193</p>
            <p>
              <a :href="'mailto:' + empresa.email">{{ empresa.email }}</a>
            </p>
            <p class="text-xl">
              <a
                class="mx-2"
                href="https://www.instagram.com/firmadeabogadoselite/"
                ><i class="fab fa-instagram"></i
              ></a>
              <a class="mx-2" href="https://www.facebook.com/elite.abogados.slp"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a
                class="mx-2"
                href="https://www.linkedin.com/company/firma-de-abogados-elite"
                ><i class="fab fa-linkedin"></i
              ></a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <footer>
      <div
        class="
          w-screen
          grid grid-cols-1
          place-content-center
          bg-dorado
          text-white text-center
          p-4
          font-sans
        "
      >
        <p v-if="lang == 'es'">
          Todos los derechos reservados &reg; {{ new Date().getFullYear() }}
          <router-link to="/privacidad" class="mx-2 text-slate-50"
            >Aviso de privacidad</router-link
          >
        </p>
        <p v-if="lang == 'en'">
          All rights reserved &reg; {{ new Date().getFullYear() }}
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
import Api from "@/components/Api";
const empresa = Api.obtenerLocal("elite-abogados.mx.empresa") || {};
export default {
  name: "Contacto",
  props: {
    mostrarDireccion: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
      empresa,
      contactoTitle: {
        es: "Contáctenos",
        en: "Contact us",
      },
      contactoPlaceholder: {
        es: "Su correo electrónico",
        en: "Your email",
      },
      contactoBoton: {
        es: "Enviar",
        en: "Send",
      },
    };
  },
};
</script>
