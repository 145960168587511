<template>
  <div>
    <Menu />
    <div class="bg-white w-screen min-h-screen md:h-auto">
      <Transition>
        <AreasDePractica v-if="area == ''" :lang="lang" />
        <AreaDePractica v-else :area="area" :lang="lang" />
      </Transition>
    </div>
    <Footer v-if="mostrarFooter" />
  </div>
</template>

<script>
import AreasDePractica from "./AreasDePractica";
import AreaDePractica from "./AreaDePractica";
import Menu from "./Menu.vue";
import Footer from "./Footer.vue";
export default {
  name: "AreasHandler",
  components: {
    AreasDePractica,
    AreaDePractica,
    Menu,
    Footer,
  },
  props: {
    lang: {
      type: String,
      default: "es",
    },
    area: {
      type: String,
      default: "",
    },
    mostrarFooter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
