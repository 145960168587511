<template>
  <section class="w-screen min-h-screen grid grid-cols-1 place-content-center">
    <div class="container mx-auto">
      <div class="grid grid-cols-1 text-center py-16 px-5 md:py-4 md:px-0">
        <h1 class="uppercase font-serif text-xl py-2" v-if="lang == 'es'">
          Áreas de práctica legal
        </h1>
        <h1 class="uppercase font-serif text-xl py-2" v-if="lang == 'en'">
          Practice Areas
        </h1>
        <!--p class="container mx-auto">
          Firma de Abogados Élite, servicio legal integral dirigido al sector
          empresarial. A continuación se proporciona una descripción general de
          nuestras áreas de práctica.
        </p-->
        <p>&nbsp;</p>
        <div class="grid grid-cols-2 md:grid-cols-3 place-content-center">
          <a
            v-for="a in areas"
            :key="a._id"
            :href="'#/areas/' + a._id"
            class="
              p-2
              no-underline
              text-center text-verde
              m-3
              grid grid-cols-1
              place-content-center
              p-3
              h-32
              drop-shadow-lg
            "
          >
            <h2 class="h-30 text-3xl py-2">
              <!--img :src="'SVG/' + a.icono" class="w-10 md:w-16 inline-block" /-->
              <ion-icon :name="a.iconoIonic"></ion-icon>
            </h2>
            <p class="font-serif">{{ a.nombre }}</p>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
ion-icon {
  font-size: 5vh;
}
</style>
<script>
import Api from "@/components/Api";
import axios from "axios";
export default {
  name: "AreasDePractica",
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
      url: Api.servidor,
      areas: [],
      titulo: {
        es: "Nuestras áreas de práctica legal",
        en: "Practice areas",
      },
      botonTitulo: {
        es: "Ver más",
        en: "Show more",
      },
    };
  },
  async mounted() {
    try {
      //const areas = await Api.readBypass("areapublic");
      const areas = await axios.get("areasdepractica.json");
      const lang = this.lang;
      this.areas = areas.data.map((a) => {
        return {
          ...a,
          nombre: a.titulo[lang] || a.titulo["es"],
        };
      });
      console.log("AReas?", this.areas);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
