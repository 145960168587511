<template>
  <div class="area font-serif">
    <div
      class="
        bg-auto bg-repeat
        bbg-[url('g858.png')]
        bg-gray-100
        min-h-screen
        md:h-auto
        pt-20
      "
    >
      <div class="container mx-auto px-6 md:px-36 lg:px-48">
        <div
          class="
            grid grid-cols-1
            ssm:grid-cols-3
            mmd:grid-cols-7
            place-content-center
          "
        >
          <div
            class="
              col-span-1
              ssm:col-span-1
              mmd:col-span-2
              md:p-5
              mb-16
              grid grid-cols-1
              place-content-start
              text-left
              md:text-center
            "
          >
            <p class="pb-8">
              <img
                :src="'../SVG/' + areaDescargada.icono"
                class="w-24 inline"
              />
            </p>
            <h1 class="uppercase text-elite text-xl">
              {{ areaDescargada.titulo[lang] }}
            </h1>
          </div>
          <div class="col-span-1 sm:col-span-2 md:col-span-5 min-h-screen mb-8">
            <p
              class="text-justify text-large"
              v-html="areaDescargada.descripcion[lang]"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.listaAbogados a {
  text-decoration: none;
  color: black;
}

.botonAtras {
  font-size: 0.5em;
  padding-top: 0.5em;
}
</style>
<script>
import Api from "@/components/Api";
import axios from "axios";
export default {
  name: "AreaDePractica",
  props: {
    area: {
      type: String,
      default: "area",
    },
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
      url: Api.servidor,
      modoProduccion: Api.modoProduccion,
      areaDescargada: {
        titulo: {
          es: "Área de práctica",
          en: "Practice area",
        },
        descripcion: {
          es: "",
          en: "",
        },
        servicios: {
          es: "",
          en: "",
        },
      },
      abogados: [],
      tituloServicios: {
        es: "Nos especializamos en",
        en: "We specialize in",
      },
      botonAtras: {
        es: "Atrás",
        en: "Back",
      },
    };
  },
  async mounted() {
    try {
      this.areaDescargada = (
        await axios.get("./areasdepractica.json")
      ).data.filter((a) => a._id == this.area)[0];
      this.abogados = (await axios.get("./abogados.json")).data.filter(
        (a) => a.areasDePractica.indexOf(this.area) >= 0
      );
      console.log("areaDescargada?", this.areaDescargada, this.abogados);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
