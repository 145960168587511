<template>
  <div class="w-full h-14 bg-verde fixed top-0 z-50">
    <div
      class="
        z-50
        h-14
        container
        mx-auto
        grid grid-cols-4
        justify-between
        place-content-center
        text-white
      "
    >
      <div class="col-span-1 grid grid-cols-1 place-content-center h-14">
        <a class="text-xl font-serif my-auto" href="#/">
          <img
            src="elite/SVG/icono_blanco.svg"
            class="w-5 inline-block pb-2 ml-4 md:ml-0"
          /><span class="hidden md:visible">&nbsp;ÉLITE</span>
        </a>
      </div>
      <div class="col-span-3 grid grid-cols-1 place-content-center h-14">
        <p class="text-xs md:text-lg font-sans my-auto text-right">
          <a
            v-for="(label, link) in secciones"
            :key="link"
            class="mr-1 md:mr-4 text-white no-underline"
            :href="'#/' + link"
            >{{ label[lang] }}</a
          >
          <a
            v-if="posts.length > 0"
            class="mr-1 md:mr-4 text-white no-underline"
            href="#/blog"
            >Blog</a
          >
          <a
            class="mr-4 text-white no-underline"
            @click="setLang(lang == 'es' ? 'en' : 'es')"
            v-if="mostrarToggleIdioma"
            >{{ lang == "es" ? "English" : "Español" }}</a
          >
          <a class="mr-4 text-white no-underline" href="tel:4441808193">
            <i class="fa-solid fa-phone"></i>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/components/Api";
export default {
  name: "Menu",
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
      secciones: {
        "#/": {
          es: "Inicio",
          en: "Home",
        },
        nosotros: {
          es: "LA FIRMA",
          en: "Law Firm",
        },
        abogados: {
          es: "Abogados",
          en: "Lawyers",
        },
        /*
        "#/contacto": {
          es: "Contacto",
          en: "Contact us",
        },//*/
      },
      posts: [],
      mostrarToggleIdioma: true,
    };
  },
  async mounted() {
    //const modoProduccion = Api.modoProduccion;
    const posts = await Api.readBypass("postpublic");
    console.log("Pre posts", posts);
    this.posts = posts;
    /*.filter(
      (p) => !p.borrador
    );//*/
    console.log("Menu Posts?", this.posts);
    Api.$emit("hayPosts", this.posts.length > 0);
  },
  methods: {
    setLang(lang = "es") {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
  },
};
</script>
